import { useVisionUIController, setModalVisibility } from "context";
import { useState } from "react";

import Fade from '@mui/material/Fade';

import VuiModal from "components/VuiModal";
import VuiBox from "components/VuiBox";
import VuiInput from 'components/VuiInput';
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const Modal = () => {
  const currencies = [
    {
      value: 'USD',
      label: '$',
    },
    {
      value: 'EUR',
      label: '€',
    },
    {
      value: 'BTC',
      label: '฿',
    },
    {
      value: 'JPY',
      label: '¥',
    },
  ];

  const [ controller, dispatch ] = useVisionUIController();
  const { editableNode, modalVisibility } = controller;

  const handleCloseModal = () => setModalVisibility(dispatch, false);

  return (
    <VuiModal 
      open={modalVisibility} 
      onClose={handleCloseModal}
    >
      <Fade in={modalVisibility}>
        <VuiBox 
          color="white" 
          bgColor="secondary" 
          borderRadius="lg" 
          shadow="lg" 
          p={3} 
          sx={style}
        >
          <VuiTypography 
            variant="h1"
            component="h1"
            textAlign="center"
            color="light"
            mb={3}
          >
            {editableNode.data.label}
          </VuiTypography>
          <Stack spacing={2}>
            <VuiInput placeholder="Type here..." />
            <VuiInput type="date" placeholder="Date" value="2018-11-23" />
            <TextField
              fullWidth
              select
              label="select..."
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Stack>            
          <VuiButton 
            onClick={handleCloseModal}
            color="secondary"
            circular={true}
            iconOnly
            sx={{
              position: 'absolute',
              top: '-1rem',
              right: '-1rem',
            }}
          >
            <CloseIcon />
          </VuiButton>
        </VuiBox>
      </Fade>
    </VuiModal>
  );
};

export default Modal;
