import VuiBox from "components/VuiBox";
import VuiTypography from 'components/VuiTypography';

const onDragStart = (event, type, title, icon, color) => {
  const nodeData = JSON.stringify({ type, title, icon, color });
  event.dataTransfer.setData("application/reactflow", nodeData);
  event.dataTransfer.effectAllowed = "move";
};

function SidebarNode({ type, title, icon, color }) {
  return (
    <VuiBox
      className="sidebar-node"
      display="flex"
      variant="gradient"
      bgColor={color}
      borderRadius="lg"
      shadow="lg"
      mb={2}
      sx={{
        border: "1px solid #0f1535",
      }}
      onDragStart={(event) => onDragStart(event, type, title, icon, color)}
      draggable
    >
      <VuiBox 
        display="flex" 
        alignItems="center" 
        borderRight="2px solid #0f1535" 
        px={1.5}
      >
        <img
          width="24"
          height="24"
          src={require(`${process.env.REACT_APP_IMAGES_BASE_PATH}${icon}`)}
          alt={title}
        />
      </VuiBox>
      <VuiBox 
        display="flex" 
        flexDirection="column" 
        flexGrow={1} 
        px={0.5} py={2}
      >
        <VuiTypography
          color="black"
          variant="body2"
          px={1.75}
        >
          {title}
        </VuiTypography>
      </VuiBox>
    </VuiBox>
  );
}

export default SidebarNode;
