import { useCallback } from 'react'
import { useReactFlow } from 'reactflow'
import { useVisionUIController, setModalVisibility, setEditableNode } from 'context'

import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import VuiButton from 'components/VuiButton'
import VuiBox from 'components/VuiBox';

import colors from "assets/theme/base/colors";

const { black } = colors;

function Toolbar({ id, color }) {
  const { getNode, setNodes, addNodes, setEdges } = useReactFlow();
  const [ controller, dispatch ] = useVisionUIController();
  
  const editNode = () => {
    const node = getNode(id);
    setEditableNode(dispatch, node);
    setModalVisibility(dispatch, true);
  };

  const deleteNode = useCallback(() => {
    setNodes((nodes) => nodes.filter((node) => node.id !== id));
    setEdges((edges) => edges.filter((edge) => edge.source !== id));
  }, [id, setNodes, setEdges]);

  const duplicateNode = useCallback(() => {
    const node = getNode(id);
    
    const position = {
      x: node.position.x + 150,
      y: node.position.y + 50,
    };

    addNodes({ ...node, id: `${id}Copy`, position });
  }, [id, getNode, addNodes]);

  return (
    <VuiBox
      position='absolute'
      bottom='-25%'
      right='0'
    >
      <VuiButton 
        circular={true}
        variant="gradient"
        color={color}
        border="1px solid #0f1535 !important"
        onClick={duplicateNode}
        iconOnly
        sx={{
          border: '1px solid #0f1535',
        }}
      >
        <ContentCopyRoundedIcon 
          sx={{
            fill: black.main,
          }}
        />
      </VuiButton>
      <VuiButton 
        circular={true}
        variant="gradient"
        color={color}
        onClick={editNode}
        iconOnly
        sx={{
          border: '1px solid #0f1535',
        }}
      >
        <EditOutlinedIcon 
          sx={{
            fill: black.main,
          }}
        />
      </VuiButton>
      <VuiButton 
        circular={true}
        variant="gradient"
        color={color}
        border="1px solid #0f1535"
        onClick={deleteNode}
        iconOnly
        sx={{
          border: '1px solid #0f1535',
        }}
      >
        <DeleteOutlinedIcon 
          sx={{
            fill: black.main,
          }}
        />
      </VuiButton>
    </VuiBox>
  );
}

export default Toolbar;