import React, { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';

// Vision UI Dashboard React components
import VuiModal from "components/VuiModal";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";

function Tables() {
  const { columns, rows } = authorsTableData;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3} mb={3}>
        <Card>
          <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
            <VuiTypography variant="lg" color="white">
              Users table
            </VuiTypography>
            <VuiButton variant="gradient" color="info" onClick={handleOpen}>
              Create User
            </VuiButton>
          </VuiBox>
          <VuiBox
            sx={{
              "& th": {
                borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                  `${borderWidth[1]} solid ${grey[700]}`,
              },
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                    `${borderWidth[1]} solid ${grey[700]}`,
                },
              },
            }}
          >
            <Table columns={columns} rows={rows} />
          </VuiBox>
        </Card>
      </VuiBox>
      <Footer />
      <VuiModal
        open={open}
        onClose={handleClose}
      >
        <Fade in={open}>
          <VuiBox 
            color="white"
            bgColor="secondary"
            // variant="gradient"
            borderRadius="lg"
            shadow="lg"
            p={2}
            sx={style}
          >
            <Typography variant="h6" component="h2">
              Text in a modal
            </Typography>
            <Typography sx={{ mt: 2 }}>
              Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
            </Typography>
          </VuiBox>
        </Fade>
      </VuiModal>
    </DashboardLayout>
  );
}

export default Tables;
