// icons from https://icons8.com/icon

export const sidebarContent = [
  {
    nodesGroupTitle: "Target",
    nodesGroupType: "target",
    color: "success",
    nodes: [
      {
        title: "Log In",
        icon: 'login.png',
      },
      {
        title: "Verification",
        icon: 'verification.png',
      },
    ],
  },
  {
    nodesGroupTitle: "Action",
    nodesGroupType: "action",
    color: "warning",
    nodes: [
      {
        title: "Send E-mail",
        icon: 'email.png',
      },
      {
        title: "Send Bonus",
        icon: 'gift.png',
      },
    ],
  },
  {
    nodesGroupTitle: "Condition",
    nodesGroupType: "condition",
    color: "primary",
    nodes: [
      {
        title: "Filter",
        icon: 'filter.png',
      },
      {
        title: "Communication Availability",
        icon: 'communication.png',
      },
    ],
  },
  {
    nodesGroupTitle: "Trigger",
    nodesGroupType: "trigger",
    color: "info",
    nodes: [
      {
        title: "Sport Bet",
        icon: 'football.png',
      },
    ],
  },
];
  