import { v4 as uuidv4 } from "uuid";

import VuiAvatar from "components/VuiAvatar";
import VuiBadge from "components/VuiBadge";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

import avatar1 from "assets/images/avatar1.png";
import avatar2 from "assets/images/avatar2.png";
import avatar3 from "assets/images/avatar3.png";
import avatar4 from "assets/images/avatar4.png";
import avatar5 from "assets/images/avatar5.png";


function Author({ image, name, email }) {
  return (
    <VuiBox display="flex" alignItems="center" px={1} py={0.5}>
      <VuiBox mr={2}>
        <VuiAvatar src={image} alt={name} size="sm" variant="rounded" />
      </VuiBox>
      <VuiBox display="flex" flexDirection="column">
        <VuiTypography variant="button" color="white" fontWeight="medium">
          {name}
        </VuiTypography>
        <VuiTypography variant="caption" color="text">
          {email}
        </VuiTypography>
      </VuiBox>
    </VuiBox>
  );
}

function Badge({ label, color, variant }) {
  return (
    <VuiBadge
      variant={variant}
      badgeContent={label}
      color={color}
      size="xs"
      container
      sx={{ marginRight: 1 }}
    />
  );
}

export default {
  columns: [{
    accessorKey: "title",
    id: "title",
    header: "title",
    muiTableBodyCellProps: {
      align: 'left',
    },
    Cell: ({ renderedCellValue }) => {
      return (
        <VuiTypography variant="caption" color="white" fontWeight="medium">
          { renderedCellValue }
        </VuiTypography>
      )
    }
  }, {
    accessorFn: (row) => row.status.label,
    id: "status",
    header: "status",
    // enableGlobalFilter: false,
    Cell: ({ renderedCellValue, row }) => (<Badge label={renderedCellValue} color={row.original.status.color} variant="contained" key={uuidv4()}/>)
  }, {
    accessorFn: (row) => `${row.firstName} ${row.lastName}`,
    id: "author",
    header: "author",
    muiTableBodyCellProps: {
      align: 'left',
    },
    Cell: ({ renderedCellValue, row }) => (<Author image={row.original.avatar} name={renderedCellValue} email={row.original.email} />)
  }, {
    accessorFn: (row) => new Date(row.created),
    id: "created",
    header: "created",
    filterFn: "lessThanOrEqualTo",
    sortingFn: "datetime",
    Cell: ({ renderedCellValue }) => {
      return (
        <VuiTypography variant="caption" color="white" fontWeight="medium">
          {renderedCellValue.toLocaleDateString()}
        </VuiTypography>
      )
    },
  }, {
    accessorFn: (row) => row.tags,
    id: "tags",
    header: "tags",
    enableSorting: false,
    Cell: ({ renderedCellValue }) => {
      return renderedCellValue.map((tag) => {
        return <Badge label={tag.label} color={tag.color} variant="gradient" key={uuidv4()}/>
      })
    }
  },
],

  data: [{
    id: uuidv4(),
    title: "Free speen for new users",
    avatar: avatar2,
    firstName: "Ivan",
    lastName: "Simonov",
    email: "Randy63@yahoo.com",
    status: {
      label: "In Progress",
      color: "success"
    },
    tags: [{
      label: "Birthday",
      color: "primary"
    }, {
      label: "VIP",
      color: "warning"
    }],
    "auto start": "23/06/29",
    "auto end": "23/06/25",
    created: "2023/04/23"
  }, {
    id: uuidv4(),
    title: "VIP deposit",
    avatar: avatar3,
    firstName: "Steffani",
    lastName: "Tailer",
    email: "Randy63@yahoo.com",
    status: {
      label: "Finished",
      color: "dark"
    },
    tags: [{
      label: "VIP",
      color: "warning"
    }],
    "auto start": "23/06/24",
    "auto end": "23/06/25",
    created: "2023/04/23"
  }, {
    id: uuidv4(),
    title: "Birthday present",
    avatar: avatar1,
    firstName: "Ash",
    lastName: "Zipper",
    email: "Randy63@yahoo.com",
    status: {
      label: "In Progress",
      color: "success"
    },
    tags: [{
      label: "Personal",
      color: "info"
    }, {
      label: "Birthday",
      color: "primary"
    }, {
      label: "VIP",
      color: "warning"
    }],
    "auto start": "23/06/22",
    "auto end": "23/06/25",
    created: "2023/04/23"
  }, {
    id: uuidv4(),
    title: "Free speen for new users",
    avatar: avatar5,
    firstName: "Ivan",
    lastName: "Simonov",
    email: "Randy63@yahoo.com",
    status: {
      label: "In Progress",
      color: "success"
    },
    tags: [{
      label: "Birthday",
      color: "primary"
    }, {
      label: "VIP",
      color: "warning"
    }],
    "auto start": "23/06/29",
    "auto end": "23/06/25",
    created: "2023/04/23"
  }, {
    id: uuidv4(),
    title: "VIP deposit",
    avatar: avatar4,
    firstName: "Steffani",
    lastName: "Tailer",
    email: "Randy63@yahoo.com",
    status: {
      label: "Finished",
      color: "dark"
    },
    tags: [{
      label: "VIP",
      color: "warning"
    }],
    "auto start": "23/06/24",
    "auto end": "23/06/25",
    created: "2023/04/23"
  }, {
    id: uuidv4(),
    title: "Birthday present",
    avatar: avatar3,
    firstName: "Ash",
    lastName: "Zipper",
    email: "Randy63@yahoo.com",
    status: {
      label: "In Progress",
      color: "success"
    },
    tags: [{
      label: "Personal",
      color: "info"
    }, {
      label: "Birthday",
      color: "primary"
    }, {
      label: "VIP",
      color: "warning"
    }],
    "auto start": "23/06/22",
    "auto end": "23/06/25",
    created: "2023/04/23"
  }, {
    id: uuidv4(),
    title: "Free speen for new users",
    avatar: avatar5,
    firstName: "Ivan",
    lastName: "Simonov",
    email: "Randy63@yahoo.com",
    status: {
      label: "In Progress",
      color: "success"
    },
    tags: [{
      label: "Birthday",
      color: "primary"
    }, {
      label: "VIP",
      color: "warning"
    }],
    "auto start": "23/06/29",
    "auto end": "23/06/25",
    created: "2023/04/23"
  }, {
    id: uuidv4(),
    title: "VIP deposit",
    avatar: avatar4,
    firstName: "Steffani",
    lastName: "Tailer",
    email: "Randy63@yahoo.com",
    status: {
      label: "Finished",
      color: "dark"
    },
    tags: [{
      label: "VIP",
      color: "warning"
    }],
    "auto start": "23/06/24",
    "auto end": "23/06/25",
    created: "2023/04/23"
  }, {
    id: uuidv4(),
    title: "Birthday present",
    avatar: avatar1,
    firstName: "Ash",
    lastName: "Zipper",
    email: "Randy63@yahoo.com",
    status: {
      label: "In Progress",
      color: "success"
    },
    tags: [{
      label: "Personal",
      color: "info"
    }, {
      label: "Birthday",
      color: "primary"
    }, {
      label: "VIP",
      color: "warning"
    }],
    "auto start": "23/06/22",
    "auto end": "23/06/25",
    created: "2020/04/23"
  }, {
    id: uuidv4(),
    title: "Free speen for new users",
    avatar: avatar2,
    firstName: "Ivan",
    lastName: "Simonov",
    email: "Randy63@yahoo.com",
    status: {
      label: "In Progress",
      color: "success"
    },
    tags: [{
      label: "Birthday",
      color: "primary"
    }, {
      label: "VIP",
      color: "warning"
    }],
    "auto start": "23/06/29",
    "auto end": "23/06/25",
    created: "2023/05/23"
  }, {
    id: uuidv4(),
    title: "VIP deposit",
    avatar: avatar3,
    firstName: "Steffani",
    lastName: "Tailer",
    email: "Randy63@yahoo.com",
    status: {
      label: "Finished",
      color: "dark"
    },
    tags: [{
      label: "VIP",
      color: "warning"
    }],
    "auto start": "23/06/24",
    "auto end": "23/06/25",
    created: "2023/04/24"
  }, {
    id: uuidv4(),
    title: "Birthday present",
    avatar: avatar1,
    firstName: "Ash",
    lastName: "Zipper",
    email: "Randy63@yahoo.com",
    status: {
      label: "In Progress",
      color: "success"
    },
    tags: [{
      label: "Personal",
      color: "info"
    }, {
      label: "Birthday",
      color: "primary"
    }, {
      label: "VIP",
      color: "warning"
    }],
    "auto start": "23/06/22",
    "auto end": "23/06/25",
    created: "2024/04/23"
  }]
};
