// Vision UI Dashboard React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

import colors from "assets/theme/base/colors";

const { white } = colors;

export default {
  defaultProps: {
    fontSize: "inherit",
  },

  styleOverrides: {
    root: {
      fill: white.main,
    },

    fontSizeInherit: {
      fontSize: "inherit !important",
    },

    fontSizeSmall: {
      fontSize: `${pxToRem(20)} !important`,
    },

    fontSizeLarge: {
      fontSize: `${pxToRem(36)} !important`,
    },
  },
};
