import VuiBox from "components/VuiBox";
import VuiTypography from 'components/VuiTypography';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import SidebarNode from "../NodeSidebar"

function FlowEditorSidebar({ sidebarContent }) {
  return (
    <VuiBox
      className="flow-editor__sidebar"
      width="300px"
      p={2}
      sx={{
        overflowY: "auto"
      }}
    >
      {sidebarContent.map((group) => {
        return (
          <Accordion
            key={ group.nodesGroupType }
            defaultExpanded={true}
            elevation={12}
            sx={{
              borderRadius: '16px !important',
            }}
          >
            <AccordionSummary >
              <VuiTypography color="white">
                { group.nodesGroupTitle }
              </VuiTypography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: '8px 16px 0',
              }}
            >
              {group.nodes.map((node, index) => {
                return (
                  <SidebarNode
                    key={index}
                    type={group.nodesGroupType}
                    title={node.title}
                    icon={node.icon}
                    color={group.color}
                  />
                );
              })}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </VuiBox>
  );
}

export default FlowEditorSidebar;