import { useCallback, useState } from 'react';

import VuiBox from "components/VuiBox";
import VuiInput from "components/VuiInput";
import VuiTypography from 'components/VuiTypography';

import Toolbar from '../Toolbar';

import colors from 'assets/theme/base/colors';

function BaseNode({ node }) {
  const { black } = colors;

  const {
    icon,
    label,
    color
  } = node.data;

  const {
    id
  } = node;
  
  const [title, setTitle] = useState(label);

  const onChange = useCallback((event) => {
    setTitle(event.target.value);
  }, []);

  return (
    <>
      <Toolbar 
        id={id}
        color={color} 
      />
      <VuiBox
        display="flex"
        variant="gradient" 
        bgColor={color} 
        borderRadius="lg" 
        shadow="lg"
        width="260px"
        sx={{
          border: '1px solid #0f1535',
          overflow: 'hidden',
        }}
      >
        <VuiBox
          display="flex"
          alignItems="center"
          borderRight="2px solid #0f1535"
          px={1.5}
        >
          <img
            width="24"
            height="24"
            src={require(`${process.env.REACT_APP_IMAGES_BASE_PATH}${icon}`)}
            alt={title}
          />
        </VuiBox>
        <VuiBox
          display="flex"
          flexDirection="column" 
          flexGrow={1}
          p={1}
          pb={3}
          sx={{
            minWidth: 0,
          }}
        >
          <VuiInput 
            value={title} 
            onChange={onChange}
            sx={{
              border: 0,
              fontSize: 16,
              color: `${black.main} !important`,
            }}
          />
          <VuiTypography
            color="black" 
            variant="caption"
            px={1.75}
            sx={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {label}
          </VuiTypography>
        </VuiBox>
      </VuiBox>
    </>
  );
}

export default BaseNode;