import { Handle, Position } from "reactflow";

import BaseNode from '../NodeBase';

function TwoHandleNode(node) {
  const {
    icon,
    label
  } = node.data;

  const {
    id,
    type
  } = node;

  return (
    <div className={`flow-node flow-node_${type}`}>
      <Handle type="target" position={Position.Left} />
      <BaseNode node={node} />
      <Handle type="source" position={Position.Right} />
    </div>
  );
} 

export default TwoHandleNode;