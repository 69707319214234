import React, { useState, useRef, useCallback } from "react";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  Panel,
} from "reactflow";
import "reactflow/dist/style.css";

import { useVisionUIController } from "context";

import style from "./style.css";

import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";

import Card from "@mui/material/Card";
import Modal from "./components/ModalEditNode";
import Sidebar from "./components/Sidebar";
import OneHandleNode from "./components/NodeOneHandle";
import TwoHandleNode from "./components/NodeTwoHandle";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { sidebarContent } from "./data/sidebarContent";

const nodeTypes = {
  target: OneHandleNode,
  action: TwoHandleNode,
  condition: TwoHandleNode,
  trigger: TwoHandleNode,
};

let id = 1;
const getNodeId = () => `node${id++}`;

const Flow = () => {
  const ref = useRef(null);
  const reactFlowWrapper = useRef(null);

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [controller] = useVisionUIController();
  const { modalVisibility } = controller;

  const onConnect = useCallback(
    (params) => {
      setEdges(addEdge({ ...params, type: "smoothstep" }, edges));
    },
    [edges]
  );

  const onSave = useCallback(() => {
    if (reactFlowInstance) {
      const flow = reactFlowInstance.toObject();
      console.log(flow);
    }
  }, [reactFlowInstance]);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      if(event.dataTransfer.getData("application/reactflow")) {
        const { type, title, icon, color } = JSON.parse(event.dataTransfer.getData("application/reactflow"));
        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
  
        const position = reactFlowInstance.project({
          x: event.clientX - reactFlowBounds.left,
          y: event.clientY - reactFlowBounds.top,
        });
  
        const nodeId = getNodeId();
  
        const newNode = {
          id: nodeId,
          type,
          position,
          data: {
            label: title,
            icon: icon,
            color: color,
          },
        };
  
        setNodes((nodes) => nodes.concat(newNode));
      }
    },
    [reactFlowInstance]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox
        py={3} 
        mb={3}
      >
        <Card>
          <VuiBox 
            display="flex" 
            height="calc(100vh - 265px)" 
            width="100%"
          >
            <ReactFlowProvider>
              <VuiBox 
                flexGrow="1" 
                ref={reactFlowWrapper}
              >
                <ReactFlow
                  ref={ref}
                  nodeTypes={nodeTypes}
                  nodes={nodes}
                  edges={edges}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  onConnect={onConnect}
                  onInit={setReactFlowInstance}
                  onDrop={onDrop}
                  onDragOver={onDragOver}
                >
                  <Controls />
                  <Panel position="top-left">
                    <VuiButton 
                      variant="gradient"
                      color="info"
                      onClick={onSave}
                    >
                      Save
                    </VuiButton>
                  </Panel>
                </ReactFlow>
              </VuiBox>
            </ReactFlowProvider>
            <Sidebar sidebarContent={sidebarContent} />
          </VuiBox>
          {modalVisibility && <Modal closeModal />}
        </Card>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Flow;
