import { forwardRef } from "react";
import { useVisionUIController, setModalVisibility } from "context";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import VuiModalRoot from "components/VuiModal/VuiModalRoot";
import VuiBox from "components/VuiBox";

import Fade from "@mui/material/Fade";

const VuiModal = forwardRef(function VuiModal({ variant, bgColor, color, opacity, borderRadius, shadow, ...rest }, ref) {
  const [controller, dispatch] = useVisionUIController();
  const { modalVisibility } = controller;

  const handleCloseModal = () => setModalVisibility(dispatch, false);

  return (
    <Fade in={modalVisibility}>
      <VuiBox>
        <VuiModalRoot
          {...rest}
          ref={ref}
          ownerState={{ variant, bgColor, color, opacity, borderRadius, shadow }}
        />
      </VuiBox>
    </Fade>
  );
});

// Setting default values for the props of VuiModal
VuiModal.defaultProps = {
  variant: "contained",
  bgColor: "transparent",
  color: "dark",
  opacity: 1,
  borderRadius: "none",
  shadow: "none",
};

// Typechecking props for the VuiModal
VuiModal.propTypes = {
  variant: PropTypes.oneOf(["contained", "gradient"]),
  bgColor: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.number,
  borderRadius: PropTypes.string,
  shadow: PropTypes.string,
};

export default VuiModal;
