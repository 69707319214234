

/** 
  All of the routes for the Vision UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Vision UI Dashboard React layouts
import Flow from "layouts/flow";
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Journey from "layouts/journey";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// Vision UI Dashboard React icons
import { IoRocketSharp } from "react-icons/io5";
import { IoIosDocument } from "react-icons/io";
import { BsFillPersonFill } from "react-icons/bs";
import { IoStatsChart } from "react-icons/io5";
import { RiDashboard3Fill } from "react-icons/ri";
import { BiSolidMessageDetail } from "react-icons/bi";
import { GiPieChart } from "react-icons/gi";
import { BsFillDiagram3Fill } from "react-icons/bs";
import { BsGiftFill } from "react-icons/bs";
import { FaUsersCog } from "react-icons/fa";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <RiDashboard3Fill size="15px" color="inherit" />,
    component: Dashboard,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Journey",
    key: "journey",
    route: "/journey",
    icon: <BsFillDiagram3Fill size="15px" color="inherit" />,
    component: Journey,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Conditions",
    key: "conditions",
    route: "/conditions",
    icon: <IoStatsChart size="15px" color="inherit" />,
    component: Tables,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Messages",
    key: "messages",
    route: "/messages",
    icon: <BiSolidMessageDetail size="15px" color="inherit" />,
    component: Tables,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Segment",
    key: "segment",
    route: "/segment",
    icon: <GiPieChart size="15px" color="inherit" />,
    component: Tables,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Bonuses",
    key: "bonuses",
    route: "/bonuses",
    icon: <BsGiftFill size="15px" color="inherit" />,
    component: Tables,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    route: "/users",
    icon: <FaUsersCog size="15px" color="inherit" />,
    component: Tables,
    noCollapse: true,
  },
  { 
    type: "divider",
    key: "divider",
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: <BsFillPersonFill size="15px" color="inherit" />,
    component: Profile,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    route: "/authentication/sign-in",
    icon: <IoIosDocument size="15px" color="inherit" />,
    component: SignIn,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    route: "/authentication/sign-up",
    icon: <IoRocketSharp size="15px" color="inherit" />,
    component: SignUp,
    noCollapse: true,
  },
  {
    name: "Flow",
    key: "flow",
    route: "/flow",
    icon: <BsFillDiagram3Fill size="15px" color="inherit" />,
    component: Flow,
    noCollapse: true,
  },
];

export default routes;
