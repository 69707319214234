//MRT Imports
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ToggleDensePaddingButton,
  MRT_ShowHideColumnsButton,
} from "material-react-table";

import { useHistory } from "react-router-dom";

//Material UI
import { Card } from "@mui/material";
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ViewWeekRoundedIcon from '@mui/icons-material/ViewWeekRounded';
import DensityLargeRoundedIcon from '@mui/icons-material/DensityLargeRounded';
import DensityMediumRoundedIcon from '@mui/icons-material/DensityMediumRounded';
import DensitySmallRoundedIcon from '@mui/icons-material/DensitySmallRounded';

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import VuiInput from "components/VuiInput";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Vision UI Dashboard  helper functions
import pxToRem from "assets/theme/functions/pxToRem";

// Data
import customJourneysData from "layouts/journey/data/customJourneysData";

function Journey() {
  const history = useHistory();
   
  const { data, columns } = customJourneysData;

  const table = useMaterialReactTable({
    columns,
    data,
    layoutMode: "grid",
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: true,
    initialState: { showGlobalFilter: true },
    positionGlobalFilter: "left",
    paginationDisplayMode: "pages",
    isMultiSortEvent: () => true,
    mrtTheme: (theme) => ({ baseBackgroundColor: theme.palette.transparent.main }),
    muiTablePaperProps: {
      elevation: 0,
      sx: (theme) => ({
        '& .MuiFormLabel-root': {
          color: theme.palette.white.main,
          fontSize: theme.typography.size.regular,
          overflow: "visible"
        },
        '& .MuiInputBase-root': {
          color: theme.palette.white.main,
        }
      })
      // sx: (theme) => ({
      //   color: theme.palette.text.main,
      // }),
    },
    icons: {
      ArrowDownwardIcon: (props) => <ExpandMoreRoundedIcon {...props} />,
      SyncAltIcon: (props) => (
        <UnfoldMoreRoundedIcon
          {...props}
          sx={{
            transform: "rotate(0) scaleX(0.9) translateX(-1px) !important",
          }}
        />
      ),
      ViewColumnIcon: (props) => <ViewWeekRoundedIcon {...props} />,
      DensitySmallIcon: (props) => <DensitySmallRoundedIcon {...props} />,
      DensityMediumIcon: (props) => <DensityMediumRoundedIcon {...props} />,
      DensityLargeIcon: (props) => <DensityLargeRoundedIcon {...props} />,
    },
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
      placeholder: "Type to search...",
      sx: {
        '& .MuiInputBase-root': {
          padding: `${pxToRem(8)} ${pxToRem(12)}`,
          borderRadius: "15px",
        }
      },
    },
    renderToolbarInternalActions: ({ table }) => (
      <VuiBox>
        <MRT_ToggleDensePaddingButton table={table} />
        <MRT_ShowHideColumnsButton table={table} />
      </VuiBox>
    ),
    positionToolbarAlertBanner: "bottom",
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => history.push("/flow"),
      sx: {
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#34476766 !important",
        },
      },
    }),
    muiTableHeadCellProps: {
      sx: (theme) => ({
        color: theme.palette.text.main,
        fontSize: theme.typography.size.xs,
        textTransform: "uppercase",
        "& .Mui-TableHeadCell-Content": {
          justifyContent: "center",
        },
        "& .Mui-TableHeadCell-Content-Actions": {
          position: "absolute",
          right: "0",
        },
      }),
    },
    muiTableBodyCellProps: {
      align: "center",
      sx: {
        textOverflow: "ellipsis",
      },
    },
    muiToolbarAlertBannerChipProps: {
      size: "small",
      sx: {
        margin: "0 0.25rem",
        "&:first-child": {
          marginLeft: "1rem",
        },
      },
    },
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 15, 20],
      shape: "rounded",
      variant: "outlined",
    },
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3} mb={3}>
        <Card>
          <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
            <VuiTypography variant="lg" color="white">
              Custom Journeys
            </VuiTypography>
            <VuiButton variant="gradient" color="info">
              Create New
            </VuiButton>
          </VuiBox>
          <VuiBox
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              padding: "1rem",
            }}
          >
            <VuiInput type="text" placeholder="Title" size="medium" />
            {/* <TextField
              fullWidth
              select
              label="select..."
            >
              {tags.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField> */}
            <VuiInput type="text" placeholder="Tags" size="medium" />
            <VuiInput type="text" placeholder="Status" size="medium" />
            <VuiInput type="text" placeholder="Author" size="medium" />
            <VuiInput type="date" placeholder="Created At" size="medium" />
            <VuiButton variant="gradient" color="info">
              Filter
            </VuiButton>
          </VuiBox>
          <MaterialReactTable table={table} />
        </Card>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Journey;